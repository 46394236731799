import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

export const useAbStore = defineStore("ab", () => {
  const isPM235VariantB = ref<Boolean>(true)

  const initPM235Experiment = () => {
    window.ymab("metrika.88769417", "getFlags", (flags: {
      pm235variant?: Array<string>
    }) => {
      isPM235VariantB.value = flags.pm235variant?.[0] === "B"
    })
  }

  return {
    isPM235VariantB,
    initPM235Experiment
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAbStore, import.meta.hot))
}
